import {useRef,useEffect, useState} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { modalModifShow,modif,update,current} from "./ChargesSlice";
import axios from 'axios';
import ListTypeCharges from './ListTypeCharges';

export default function ModalModifier() {

    const apiUrl = process.env.REACT_APP_API_URL;
    const ChargeData = useSelector(state => state.chargesData);
    const currentelement = ChargeData.current;
    const modalShow = ChargeData.modalModifShow;
    const id = useSelector(state => state.userData.id);
    const [loading,setLoading] =useState(false)

    const catField = useRef('');
    const montantField = useRef('');
    const dateField = useRef('');
    const designationField = useRef('');

    const [etat,setEtat] =useState('')

    const dispatch= useDispatch();

    const formatDateToFrench = (dateString) => {
        try {
        const [date] = dateString.split('T');
        const [year, month, day] = date.split('-');
        return `${year}-${month}-${day}`;
        }
        catch (error) {
        }  
      };

      const formatDateToIso = (dateString) => {
        const formattedDate = new Date(`${dateString}T00:00:00Z`);
        formattedDate.setHours(23, 59, 59, 999);
        return formattedDate.toISOString();
        };

        const Close = () => {
            dispatch(modalModifShow(false));
            setEtat('');
            
        };

          const ChampUpdate = () =>{
            const _id = currentelement._id;
            const categorie=catField.current.value
            const montant=montantField.current.value;
            const designation = designationField.current.value;
            const date = dateField.current.value;
            const frequence = currentelement.frequence
            const idcharge = currentelement.idcharge
            
            const valeur={_id,categorie,montant,designation,date,frequence,idcharge}
            dispatch(current(valeur));
          }

          const Ajouter = async ()=> {
            const idcharge = currentelement._id;
            const idperp = currentelement.idcharge;
            const frequence = currentelement.frequence
            const categorie = catField.current.value
            const designation=designationField.current.value.trim()
            const date=formatDateToIso(dateField.current.value)
            let montant=montantField.current.value
            montant=montant.replace(",", ".")
            setEtat('')
            setLoading(true)
            try {

                if(frequence ==="Perpétuelle"){
                    const response = await axios.post(`${apiUrl}edit/chargePerpetuelle/${id}/${idperp}`,{
                        categorie,
                        montant,
                        designation,
                    });  
                }

                const response = await axios.post(`${apiUrl}edit/charge/${id}/${idcharge}`, {
                    categorie,
                    montant,
                    designation,
                    date,
                });
                if(response.data.status==="ok")
                    {
                        const data={idcharge,categorie,montant,designation,date};
                        dispatch(modalModifShow(false));
                        dispatch(modif(true));
                        dispatch(update(data));
                    }
                    else{
                        setEtat('erreur');
                    }
                } catch (error) {
                    setEtat('erreur');
                }
                setLoading(false)
        }

          useEffect(() =>{
            setEtat('');
          },[ChargeData]);

    return (
        <div className={`modal fade ${modalShow ? 'show' : ''}`} style={{ display: modalShow ? 'block' : 'none' }} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Ajouter une charge</h5>
                    </div>
                    <div className="modal-body">
                    {etat==='erreur' ?
                                <div class="alert alert-danger  mb-4 text-center" role="alert">
                                <strong>Erreur de connexion</strong>
                            </div>                 
                        :
                        ''                    
                    }

                    <label className="form-label" for="categorie">Catégorie</label>
                    <select className="form-select form-select" id="categorie" ref={catField} value={currentelement.categorie} onChange={ChampUpdate}>
                  <ListTypeCharges/>
                    </select>
            
                    <label className="form-label mt-3" for="montant">Montant</label>
                    <input type="number" className="form-control" id="contact" ref={montantField} value={currentelement.montant} onChange={ChampUpdate}></input>

                    <label className="form-label mt-3" for="designation">Désignation</label>
                    <input type="text" className="form-control" id="designation" ref={designationField} value={currentelement.designation} onChange={ChampUpdate}></input>
                    
                    <label className="form-label mt-3" for="date">Date</label>
                    <input type="date" className="form-control" id="date" ref={dateField} value={formatDateToFrench(currentelement?.date)} onChange={ChampUpdate}></input>

                    </div>
                    <div className="modal-footer">
                        {loading ?
                        <>
                <div className="mt-4 d-flex justify-content-center">
                <button class="btn btn-success" type="button" disabled>
                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                <span class="sr-only">Enregistrement...</span>
                </button>
                </div> 
                        </>
                        :
                        <>
                        <button type="button" className="btn btn-success" onClick={Ajouter}>Enregistrer</button>
                        <button type="button" className="btn btn-secondary" onClick={Close}>Annuler</button>
                        </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
