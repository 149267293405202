import {useState,useEffect,useRef} from 'react';
import axios from 'axios';
import { useSelector,useDispatch } from 'react-redux';
import { showmodal,getList,ajout,modalModifShow,modif,current,remove } from "./ProduitsSlice";
import ModalAjouter from './ModalAjouter';
import Catlist from './Catlist';
import ModalModifer from './ModalModifier';
import Fournisseurs from './Fournisseur';

export default function Produits() {
    const [Chargement, setChargement] = useState(false);
    const ajoutSuccess=useSelector(state => state.produitsData.ajoutOk);
    const [removeSuccess, setRemoveSuccess] = useState(false);
    const [rechercheInput,setRchercheInput] = useState('');
    const [rechercheCat,setRchercheCat] = useState('tout');
    const [rechercheFourniss,setrechercheFourniss] = useState('tout');
    const [idCurrentcat,SetidCurrentcat] = useState('');
    const rechercheField=useRef('');
    const catField=useRef('');
    const fournisseurField = useRef('');
    
    const id = useSelector(state => state.userData.id);
    const listProduits=useSelector(state => state.produitsData.list);
    const modifSuccess=useSelector(state => state.produitsData.modifOk);
    const dispatch= useDispatch();

    const getProduits=async () => {
        setChargement(true);
        try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.post(`${apiUrl}produits/${id}`);
        dispatch(getList(response.data));
        }
     catch (error) {
        console.error('Error:', error);    
    }
    setChargement(false);
    }

    const Ajouter = () => dispatch(showmodal(true));

    const modifier = (id) => {
        dispatch(modalModifShow(true));
        const valeur = listProduits.find(produit => produit._id === id);
        const idcat = valeur.categorie.map(prod => prod.id)
        SetidCurrentcat({idcat})
        dispatch(current(valeur));
    };

    const HideSuccessMsg = () => {
        setTimeout(() => {
            dispatch(ajout(false));
            dispatch(modif(false));
            setRemoveSuccess(false);
        }, 5000);
    }

    const suppression = async (idprod)=> {

        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await axios.post(`${apiUrl}delete/produit/${id}/${idprod}`);
            if(response.data.status==="ok")
                {
                    dispatch(remove(idprod));
                    setRemoveSuccess(true);
                    HideSuccessMsg();
                }
        } catch (error) {
            console.log(error)
        }
    }

    const supprimer = (idprod) => {
        let valeur = listProduits.find(produit => produit._id === idprod);
        valeur=valeur.designation
        let result = window.confirm(`Supprimer le produit ${valeur} ?`);
        if (result === true) {
            suppression(idprod);
        }
    }
    
    function ShowList() {
        HideSuccessMsg();
        if(listProduits.length > 0){
            let listFiltre=listProduits
            if(rechercheInput.length > 2) {
                listFiltre = listFiltre.filter(produit => produit.designation.includes(rechercheInput))
            }
            if (rechercheCat !=="tout"){
                listFiltre = listFiltre.filter(produit => 
                    produit.categorie.some(categorie => 
                      categorie.id===rechercheCat) 
                    )
                ;               
            }
            if (rechercheFourniss !=="tout"){
                listFiltre = listFiltre.filter(produit => 
                    produit.fournisseur.some(fournisseur => 
                        fournisseur.id===rechercheFourniss) 
                    )
                ;               
            }
        return listFiltre.map(Produit => {
            return(
                <tr key={Produit._id}>
                <td>{Produit.designation}</td>
                <td>{Produit.fournisseur["0"]?.designation}</td>
                <td>{Produit.categorie["0"]?.designation}</td>
                <td>{Produit.prix_achat}</td>
                <td>{Produit.prix_vente}</td>
                <td>{Produit.tva}</td>
                <td>
                <button type="button" class="btn btn-primary me-4" onClick={() => modifier(Produit._id)}>Modifier</button>
                <button type="button" class="btn btn-danger" onClick={() => supprimer(Produit._id)}>Supprimer</button>
                </td>
            </tr>                
            )
        })
    }
    else{
        return <tr>
            <td colSpan={7} align='center'>Pas de données</td>
        </tr>;
    }
    }
    const Recherche=(event) =>{
        const recherche=rechercheField.current.value
        const idcat=catField.current.value
        const idfourniss=fournisseurField.current.value
        if(recherche!==rechercheInput){
        setRchercheInput(recherche)
         }
         if(idcat!==rechercheCat){
        setRchercheCat(idcat)
    }
    if(idfourniss!==rechercheFourniss){
        setrechercheFourniss(idfourniss)
    }

    }
    
useEffect(() =>{
    getProduits();
    dispatch(showmodal(false));
    },[]);

  return (
    <>
    <div className="container-lg align-items-center">
<section className="mt-4">
    <p className="h2 text-center">Produits</p>

    {Chargement? 
                <div d-flex className="d-flex justify-content-center">
                <div className="spinner-grow" style={{width: '3rem', height: '3rem'}} role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              </div>
            :
            <div style={{ height: '3rem' }}></div>
            }

    <div className="d-flex justify-content-end mb-3">
        
    <button className="btn btn-primary mr-2" onClick={Ajouter}>
      <i className="fas fa-user-plus"></i> Ajouter un produit
    </button>
  </div>

  <div className="d-flex justify-content-start mb-3 w-50" >
        
  <input type="text" className="form-control w-50 " id="designation"  placeholder="Recherche produit" ref={rechercheField} onKeyUp={Recherche} ></input>

  <select className="form-select form-select-sm w-25 ms-2" id="fournisseur" ref={fournisseurField} value={rechercheFourniss} onChange={Recherche}>
            <option value="tout">Fournisseurs</option>
            <Fournisseurs/>
</select>
  <select className="form-select form-select-sm w-25 ms-2" id="categorie" ref={catField} value={rechercheCat} onChange={Recherche}>
            <option value="tout">Catégories</option>
            <Catlist/>
</select>
    </div>

  {ajoutSuccess ?
        <div class="alert alert-success  mb-4 text-center" role="alert">
        <strong>Produit ajouté avec succès</strong>
        </div>                 
            :
            ''                    
        }

{modifSuccess ?
        <div class="alert alert-success  mb-4 text-center" role="alert">
        <strong>Produit modifié avec succès</strong>
        </div>                 
            :
            ''                    
        }

{removeSuccess ?
        <div class="alert alert-success  mb-4 text-center" role="alert">
        <strong>Produit supprimé avec succès</strong>
        </div>                 
            :
            ''                    
        }
        <div class="table-responsive">
  <table className="table  align-middle table-dark table-striped mb-3">
        <thead>
            <tr>
                <th scope="col">Désignation</th>
                <th scope="col">Fournisseur</th>
                <th scope="col">Catégorie</th>
                <th scope="col">Prix d'achat</th>
                <th scope="col">Prix de vente</th>
                <th scope="col">TVA</th>
                <th scope="col">Action</th>
            </tr>
        </thead>
        <tbody>
            <ShowList/>
        </tbody>
    </table>
    </div>
</section>
    </div>
    <ModalAjouter />
    <ModalModifer Idcat={idCurrentcat} />
</>
    
  )

}
